import Shipping1 from "../images/Shipping4.jpg";
import background from "../images/background.png";
import Line from "../images/Line.svg";
import { CheckIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import Timeline from "../images/career-timeline.png";
import { useState } from "react";
import en from "../translations/en.json";
import el from "../translations/el.json";

export const Shipping = ({currentLang}: {currentLang: string}) => {
  const [ service, setService] = useState<string>('team');
  const translation = { en, el };
  const t = (key: string) => {
    //  @ts-ignore
      return translation[currentLang]["shipping"][key]; 
  }

  return (
    <div className="px-2.5 md:px-28 lg:px-32 md:mt-24 mt-20 space-y-3 md:pb-20 pb-8">
      {/* Section 1 */}
      <div className="flex lg:flex-row flex-col-reverse rounded-tl-3xl bg-[#DAD7FF]">
        <div className="flex flex-col lg:justify-left lg:pl-24 lg:py-20 lg:w-1/2 px-10 lg:px-7 py-7">
          <div className={`flex gap-2 md:gap-4 ${currentLang === "el" ? "flex-col": ""}`}>
            {" "}
            <p className="text-[#3B31DE] md:text-5xl text-2xl font-bold ">
              {t("hero-page-header-color1")}
            </p>
            <p className="text-black md:text-5xl text-2xl font-bold ">
              {t("hero-page-header-color2")}
            </p>
          </div>
          <p className="text-black md:text-3xl text-xl font-bold md:mt-3 mt-1">
            {t("hero-page-subtitle")}
          </p>

          <p className="text-black text-left font-medium md:text-lg text-md mt-10">
              {t("hero-page-paragraph")}
              <br/>
              <br/>
              {t("hero-page-paragraph-2")}
          </p>
          <div className="flex space-x-5 md:mt-12 mt-8 md:mb-0 mb-12 ">
            {/*<button
              type="button"
              className="inline-flex w-full justify-center rounded-lg  text-indigo-600 border-2 border-indigo-600   hover:bg-indigo-600 px-4 py-3 text-md  hover:text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
              onClick={() => navigate("/")}
            >
              Get Started
            </button>
            <button
              type="button"
              className=" inline-flex w-full justify-center rounded-lg  text-indigo-600 border-2 border-indigo-600   hover:bg-indigo-600 px-2 py-3 text-md  hover:text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
            >
              Book a Meeting
            </button>*/}
          </div>
        </div>
        <div className="lg:mr-2 lg:-mt-12 -mt-10 lg:w-1/2 lg:mb-20 px-7">
          <img
            src={Shipping1}
            alt="Shipping"
            className="rounded-2xl h-48 w-full md:h-full"
          />
        </div>
      </div>

      {/* section 2 */}
      <div className="flex lg:flex-row flex-col bg-[#05005D] ">
        <div className=" lg:ml-10 lg:-mt-12 -mt-10 lg:fit lg:mb-20 lg:w-1/2 px-7">
          <img
            src={Timeline}
            alt="Timeline"
            className="rounded-2xl h-48 w-full md:h-full"
          />
        </div>
        <div className="flex flex-col lg:justify-end md:pr-20 md:pl-8 md:pb-16 lg:pt-10 lg:w-1/2 mt-5 p-10">
          <div>
            {" "}
            <p className="text-[#847CFF] md:text-4xl text-2xl font-normal uppercase">
            {t("main-content-heading-color1")}
            </p>
            <p className="text-white md:text-4xl text-2xl font-bold mt-2">
            {t("main-content-heading-color2")}
            </p>
          </div>

          <p className="text-white text-left font-normal md:mt-6 mt-4">
            {t("main-content-para")}
            <br />
            <br />
            <p className="mb-4">{t("main-content-list-header")}</p>
            <ul className="list-disc pl-5 mb-4">
              <li>{t("main-content-list-item1")}</li>
              <li>{t("main-content-list-item2")}</li>
              <li>{t("main-content-list-item3")}</li>
              <li>{t("main-content-list-item4")}</li>
            </ul>
          </p>

          {/* <div>
            <img className="md:mt-12 w-full mt-4" src={Timeline} alt="timeline" />
          </div> */}
        </div>
      </div>

      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="  md:px-28 md:py-20 px-7 py-10 w-full"
      >
        <div>
          <p className="text-[#05005D] md:text-4xl text-2xl font-bold uppercase">
            {t("service-title-color1")}
          </p>
          <p className="text-white md:text-4xl text-2xl font-bold uppercase ">
            {t("service-title-color2")}
          </p>
        </div>
        <div className="flex">
          <button 
          className={`md:mt-16 mt-7 text-white font-bold md:text-xl text-lg w-1/2 bg-[#3B31DE] p-2 border-r-2 ${service === "team" ? "border-b-2" : ""}`}
          onClick={() => setService('team')}
          >
            {t("services-first-button-header")}
          </button>

          <button 
          className={`md:mt-16 mt-7 text-white font-bold md:text-xl text-lg w-1/2 bg-[#3B31DE] p-2 ${service === "software" ? "border-b-2 border-" : ""}`}
          onClick={() => setService('software')}
          >
            {t("services-second-button-header")}
          </button>
        </div>
          {
            service === "team" && 
            (
              <>
              <p className="md:mt-10 mt-4 text-white font-bold md:text-xl text-lg">
              {t("services-first-button-para")}
              </p>
                
                <div className="flex-col md:text-lg text-md">
                        
                <div className="flex space-x-1 mt-6">
                <CheckIcon className="h-7 w-7 text-[#3B31DE]" />
                  <div className="text-white flex flex-col">
                    <p className="font-medium text-[#05005D]">
                      {t("services-first-list-item1")}
                    </p>
                  </div>
                </div>
                          
                <div className="flex space-x-1 mt-6">
                <CheckIcon className="h-7 w-7 text-[#3B31DE]" />
                  <div className="text-white flex flex-col">
                  <p className="font-medium text-[#05005D]">
                  {t("services-first-list-item2")} 
                    </p>
                  </div>
                </div>
                          
                <div className="flex space-x-1 mt-6">
                <CheckIcon className="h-7 w-7 text-[#3B31DE]" />
                  <div className="text-white flex flex-col">
                  <p className="font-medium text-[#05005D]">{t("services-first-list-item3")}</p>
                  </div>
                </div>
                          
                <div className="flex space-x-1 mt-6">
                <CheckIcon className="h-7 w-7 text-[#3B31DE]" />
                  <div className="text-white flex flex-col">
                  <p className="font-medium text-[#05005D]">{t("services-first-list-item4")}</p>
                  </div>
                </div>
                </div>
                <p className="md:mt-10 mt-7 text-white font-bold md:text-lg text-md">
                {t("services-first-button-second-para")}
                </p>
                          
                <div className="flex flex-col md:text-lg text-md">
                <div className="flex space-x-1 mt-6">
                <CheckIcon className="h-7 w-7 text-[#3B31DE]" />
                    <div className="text-white flex flex-col">
                    <p className="font-medium text-[#05005D]">
                        {t("services-second-list-item1")}
                      </p>
                    </div>
                </div>
                          
                <div className="flex space-x-1 mt-6">
                <CheckIcon className="h-7 w-7 text-[#3B31DE]" />
                    <div className="text-white flex flex-col">
                    <p className="font-medium text-[#05005D]">
                    {t("services-second-list-item2")}
                      </p>
                    </div>
                </div>
                          
                <div className="flex space-x-1 mt-6">
                <CheckIcon className="h-7 w-7 text-[#3B31DE]" />
                  <div className="text-white flex flex-col">
                  <p className="font-medium text-[#05005D]">{t("services-second-list-item3")}</p>
                  </div>
                </div>
                </div>
                <p className="md:mt-16 mt-7 text-white font-medium md:text-lg text-md">
                 {t("services-first-button-last-para")}
                </p>            
            </>
          )
        }

        {
          service === "software" && 
          (
            <>
              <p className="lg:mt-16 md:mt-10 mt-4 text-white font-bold md:text-xl text-lg">
                  {t("services-second-button-para")}
              </p>
            <div className="flex lg:flex-row lg:flex-wrap flex-col gap-6 lg:items-start md:text-lg text-md">
            {[
              {
                title: t("services-second-button-list-item1-header"),
                description: t("services-second-button-list-item1-para"),
              },
              {
                title: t("services-second-button-list-item2-header"),
                description: t("services-second-button-list-item2-para"),
              },
              {
                title: t("services-second-button-list-item3-header"),
                description: t("services-second-button-list-item3-para"),
              },
              {
                title: t("services-second-button-list-item4-header"),
                description: t("services-second-button-list-item4-para"),
              },
              {
                title: t("services-second-button-list-item5-header"),
                description: t("services-second-button-list-item5-para"),
              },
              {
                title: t("services-second-button-list-item6-header"),
                description: t("services-second-button-list-item6-para"),
              },
              {
                title: t("services-second-button-list-item7-header"),
                description: t("services-second-button-list-item7-para"),
              },
            ].map((item, index) => (
              <div
                key={index}
                className="flex space-x-3 items-start w-full md:w-1/3 mt-4"
              >
                <CheckIcon className="h-7 w-7 mt-1 text-[#3B31DE]" />
                <div className="text-white">
                  <p className="font-medium text-[#05005D]">{item.title}</p>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div> 
            </>
          )
        }
        <img className="md:mt-12 w-full h-1 mt-4" src={Line} alt="line" />
      </div>

        

     
      <section className="py-16 bg-[#05005D]">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-8 text-white"><span className="text-[#847CFF]">{t("contact-header-color1")}</span> {t("contact-header-color2")}</h2>
        <p className="text-lg text-white font-medium mb-12 max-w-2xl mx-auto">
          {t("contact-para")}
        </p>
        <button
          className="inline-flex items-center bg-[#847CFF] text-white font-semibold py-3 px-6 gap-2 rounded-full transition-colors duration-300"
          onClick={() => window.location.href = 'mailto:contact@entnt.in'}
        >
          <EnvelopeIcon className="h-5 w-5"/>
          {t("contact-button-title")}
        </button>
      </div>
    </section>
    </div>
  );
};
