import Image from "../images/SoftwareImage.png";
import background from "../images/background.png";
import { CheckIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import WhyEntnt1 from "../images/WhyEntnt1.svg";
import WhyEntnt2 from "../images/WhyEntnt2.svg";
import WhyEntnt3 from "../images/WhyEntnt3.svg";
import WhyEntnt4 from "../images/WhyEntnt4.svg";
import Line from "../images/Line.svg";
import Timeline from "../images/career-timeline.png";
import en from "../translations/en.json";
import el from "../translations/el.json";

export const Software = ({currentLang}: {currentLang: string}) => {
  const translation = { en, el };
  const t = (key: string) => {
    //  @ts-ignore
      return translation[currentLang]["software"][key]; 
  }
  return (
    <div className="px-2.5 md:px-28 lg:px-32 md:mt-24 mt-20 space-y-3 md:pb-20 pb-8">
      {/* section1 */}
      <div className="flex lg:flex-row flex-col-reverse rounded-tl-3xl bg-[#DAD7FF]">
        <div className="flex flex-col lg:justify-left lg:pl-24 lg:py-20 lg:w-1/2 px-10 lg:px-7 py-7">
          <div className={`flex gap-2 md:gap-4 ${currentLang === "el" ? "flex-col" : ""}`}>
            {" "}
            <p className="text-[#3B31DE] md:text-5xl text-2xl font-bold ">
              {t("hero-page-header-color1")}
            </p>
            <p className="text-black ml-0 md:text-5xl text-2xl font-bold ">
            {t("hero-page-header-color2")}
            </p>
          </div>
          <p className="text-black md:text-3xl text-xl font-bold md:mt-3 mt-1">
          {t("hero-page-subtitle")}
          </p>
          <p className="text-black text-left font-medium md:text-lg text-md mt-10">
          {t("hero-page-paragraph")}
           <br/>
           <br/>
           {t("hero-page-paragraph-2")}
           <br/>
           <br/>
           {t("hero-page-paragraph-3")}
          </p>
          <div className="flex space-x-5 md:mt-12 mt-8 md:mb-0 mb-12 ">
            {/*<button
              type="button"
              className="inline-flex w-full justify-center rounded-lg  text-indigo-600 border-2 border-indigo-600   hover:bg-indigo-600 px-2 py-3 text-md  hover:text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
              onClick={() => navigate("/")}
            >
              Get Started
            </button>
            <button
              type="button"
              className=" inline-flex w-full justify-center rounded-lg  text-indigo-600 border-2 border-indigo-600   hover:bg-indigo-600 px-2 py-3 text-md  hover:text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
            >
              Book a Meeting
            </button>*/}
          </div>
        </div>
        <div className=" lg:mr-2 lg:-mt-12 -mt-10 lg:w-1/2 lg:mb-20 px-7">
          <img
            src={Image}
            alt="software"
            className="rounded-2xl h-48 w-full md:rounded md:h-[90%] "
          />
        </div>
      </div>
      
      {/*section 2*/}
      <div className="flex lg:flex-row flex-col bg-[#05005D] ">
        <div className=" lg:ml-10 lg:-mt-12 -mt-10 lg:fit lg:mb-20 lg:w-1/2 px-7">
          <img
            src={Timeline}
            alt="Timeline"
            className="rounded-2xl h-48 w-full md:h-full"
          />
        </div>
        <div className="flex flex-col lg:justify-end md:pr-20 md:pl-8 md:pb-16 lg:pt-10 lg:w-1/2 mt-5 p-10">
          <div>
            {" "}
            <p className="text-[#847CFF] md:text-4xl text-2xl font-normal uppercase">
            {t("main-content-heading-color1")} 
            </p>
            <p className="text-white md:text-4xl text-2xl font-bold mt-2">
            {t("main-content-heading-color2")}
            </p>
          </div>

          <p className="text-white text-left font-normal md:mt-6 mt-4">
            {t("main-content-para")}
            <br />
            <br />
            <p className="mb-4">{t("main-content-list-header")}</p>
            <ul className="list-disc pl-5 mb-4">
              <li>{t("main-content-list-item1")}</li>
              <li>{t("main-content-list-item2")}</li>
              <li>{t("main-content-list-item3")}</li>
              <li>{t("main-content-list-item4")}</li>
            </ul>
          </p>

          {/* <div>
            <img className="md:mt-12 w-full mt-4" src={Timeline} alt="timeline" />
          </div> */}
        </div>
      </div>


      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="  md:px-28 md:py-20 px-7 py-10 w-full"
      >
        <div>
          <p className="text-[#05005D] md:text-4xl text-2xl font-bold uppercase">
            {t("service-title-color1")}
          </p>
          <p className="text-white md:text-4xl text-2xl font-bold uppercase ">
            {t("service-title-color2")}
          </p>
        </div>
            <p className="md:mt-16 mt-4 text-white font-bold md:text-xl text-lg">
            {t("services-header")}
            </p>
              
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-4 lg:items-start md:text-lg text-md ">
              {[
                {
                  title: t("services-list-item1-title"),
                  description: t("services-list-item1-desc"),
                },
                {
                  title: t("services-list-item2-title"),
                  description: t("services-list-item2-desc"),
                },
                {
                  title: t("services-list-item3-title"),
                  description: t("services-list-item3-desc"),
                },
                {
                  title: t("services-list-item4-title"),
                  description: t("services-list-item4-desc"),
                },
                {
                  title: t("services-list-item5-title"),
                  description: t("services-list-item5-desc"),
                },
                {
                  title: t("services-list-item6-title"),
                  description: t("services-list-item6-desc"),
                },
                {
                  title: t("services-list-item7-title"),
                  description: t("services-list-item7-desc"),
                },
                {
                  title: t("services-list-item8-title"),
                  description: t("services-list-item8-desc"),
                  subpoints: [
                    {
                      title: t("services-list-item8-subpoints1-title"),
                      description: t("services-list-item8-subpoints1-desc")
                    },
                    {
                      title: t("services-list-item8-subpoints2-title"),
                      description: t("services-list-item8-subpoints2-desc")
                    },
                    {
                      title: t("services-list-item8-subpoints3-title"),
                      description: t("services-list-item8-subpoints3-desc")
                    }
                  ]
                }
              ].map((item, index) => (
                <div
                  key={index}
                  className={`flex space-x-2 mt-6 w-full ${item.subpoints ? "md:col-span-2" : ""}`}
                >
                  <CheckIcon className={`h-28 w-28 -mt-9 text-[#3B31DE] ${item.subpoints ? "h-7 w-7 !mt-1" : ""}`} />
                  <div className="text-white">
                    <p className="font-medium text-[#05005D]">{item.title}</p>
                    <p>{item.description}</p>
                    {item.subpoints && (
                      <ul className="mt-2 space-y-2">
                        {item.subpoints.map((subpoint, subIndex) => (
                          <li key={subIndex}>
                            <h6 className="text-white font-bold inline">{subpoint.title}</h6> {subpoint.description}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              ))}
            </div>
        <img className="md:mt-12 w-full h-1 mt-4" src={Line} alt="line" />
      </div>

      {/*section 3*/}
      <div>
        <p className="text-black font-bold md:text-4xl text-2xl text-center md:mt-20 mt-10 md:uppercase">
          Why ENTNT?
        </p>

        <div className="flex flex-wrap lg:flex-nowrap space-x-2 justify-center md:mt-20 mt-16">
          <div className="flex flex-col justify-center items-center space-y-6 bg-[#F6F7F8] md:px-12 px-6 pb-10 max-w-[500px] mb-20 rounded-lg relative">
            <img className="h-16 w-16 absolute -top-10 bg-[#7064FF] rounded-xl" src={WhyEntnt1} alt="why entnt1"/>
            <p className="md:text-lg text-md font-bold text-center pt-10">
               {t("cta-list-item1-title")}
            </p>
            <p className="text-md text-center">
            {t("cta-list-item1-desc")}
            </p>
          </div>
          <div className="flex flex-col justify-center items-center space-y-6 bg-[#F6F7F8] md:px-12 px-6 pb-10 max-w-[500px] mb-20 rounded-lg relative">
            <img className="h-16 w-16 absolute -top-10 bg-[#7064FF] rounded-xl" src={WhyEntnt2}  alt="why entnt2"/>
            <p className="md:text-lg text-md font-bold text-center pt-10">
            {t("cta-list-item2-title")}{" "}
            </p>
            <p className="text-md text-center">
            {t("cta-list-item2-desc")}
            </p>
          </div>
          <div className="flex flex-col justify-center items-center space-y-6 bg-[#F6F7F8] md:px-12 px-6 pb-10 max-w-[500px] mb-20 rounded-lg relative">
            <img className="h-16 w-16 absolute -top-10 bg-[#7064FF] rounded-xl" src={WhyEntnt3}  alt="why entnt3"/>
            <p className="md:text-lg text-md font-bold text-center pt-10">
              {" "}
              {t("cta-list-item3-title")}
            </p>
            <p className="text-md text-center">
            {t("cta-list-item3-desc")}
            </p>
          </div>
          <div className="flex flex-col justify-center items-center space-y-6 bg-[#F6F7F8] md:px-12 px-6 pb-10 max-w-[500px] mb-20 rounded-lg relative">
            <img className="h-16 w-16 absolute -top-10 bg-[#7064FF] rounded-xl" src={WhyEntnt4}  alt="why entnt4"/>
            <p className="md:text-lg text-md font-bold text-center pt-10">
              {" "}
              {t("cta-list-item4-title")}
            </p>
            <p className="text-md text-center">
            {t("cta-list-item4-desc")}
            </p>
          </div>
        </div>
      </div>

      <section className="py-16 bg-[#05005D]">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-8 text-white"><span className="text-[#847CFF]">{t("contact-header-color1")}</span> {t("contact-header-color2")}</h2>
        <p className="text-lg text-white font-medium mb-12 max-w-2xl mx-auto">
          {t("contact-para")}
        </p>
        <button
          className="inline-flex items-center bg-[#847CFF] text-white font-semibold py-3 px-6 gap-2 rounded-full transition-colors duration-300"
          onClick={() => window.location.href = 'mailto:contact@entnt.in'}
        >
          <EnvelopeIcon className="h-5 w-5"/>
          {t("contact-button-title")}
        </button>
      </div>
    </section>
    </div>
  );
};
